var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"名称："}},[_c('el-input',{attrs:{"size":"small"},model:{value:(_vm.searchForm.name),callback:function ($$v) {_vm.$set(_vm.searchForm, "name", $$v)},expression:"searchForm.name"}})],1),_c('el-form-item',{attrs:{"label":"手机号："}},[_c('el-input',{attrs:{"size":"small"},model:{value:(_vm.searchForm.mobile),callback:function ($$v) {_vm.$set(_vm.searchForm, "mobile", $$v)},expression:"searchForm.mobile"}})],1),_c('el-form-item',{attrs:{"label":"性别："}},[_c('el-select',{attrs:{"size":"small"},model:{value:(_vm.searchForm.gender),callback:function ($$v) {_vm.$set(_vm.searchForm, "gender", $$v)},expression:"searchForm.gender"}},[_c('el-option',{attrs:{"label":"全部","value":-1}}),_c('el-option',{attrs:{"label":"未知","value":0}}),_c('el-option',{attrs:{"label":"男","value":1}}),_c('el-option',{attrs:{"label":"女","value":2}})],1)],1),_c('el-form-item',{attrs:{"label":" ","label-width":"20px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.search}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":_vm.cancel}},[_vm._v("清空搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.list,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"prop":"name","label":"员工信息","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"user-info"},[_c('el-image',{attrs:{"src":row.avatar ? row.avatar : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/userDefault.png '}}),_c('span',[_vm._v(_vm._s(row.name || '--'))])],1)]}}])}),_c('el-table-column',{attrs:{"prop":"mobile","label":"手机号","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.mobile || '--')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"position","label":"职位","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.position || '--')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"gender","label":"性别","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.gender == 0 ? '未知' : row.gender == 1 ? '男' : '女')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"email","label":"邮箱","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.email || '--')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"department_name","label":"部门名称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.department_name || '--')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"激活状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.status == 1 ? '已激活' : row.status == 2 ? '已禁用' : row.status == 4 ? '未激活' : '成员退出')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"qr_code","label":"企微二维码","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.qr_code)?_c('el-image',{staticStyle:{"width":"60px","height":"60px"},attrs:{"preview-src-list":[row.qr_code],"src":row.qr_code}}):_c('span',[_vm._v("--")])]}}])}),_c('el-table-column',{attrs:{"prop":"is_client","label":"设置平台客服","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},on:{"change":function($event){return _vm.setIsClient(row)}},model:{value:(row.is_client),callback:function ($$v) {_vm.$set(row, "is_client", $$v)},expression:"row.is_client"}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push({ path: '/user/enterpriseWechat/staffDetail', query: { user_id: row.user_id } })}}},[_vm._v("查看客户")])]}}])})],2),_c('Paging',{staticClass:"paging",attrs:{"total":_vm.total_number,"page":_vm.searchForm.page,"pageNum":_vm.searchForm.rows},on:{"updatePageNum":_vm.updateData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }