<template>
  <el-main>
    <el-form class="el-form-search" label-width="80px">
      <el-form-item label="名称：">
        <el-input v-model="searchForm.name" size="small"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input v-model="searchForm.mobile" size="small"></el-input>
      </el-form-item>
      <el-form-item label="性别：">
        <el-select v-model="searchForm.gender" size="small">
          <el-option label="全部" :value="-1"></el-option>
          <el-option label="未知" :value="0"></el-option>
          <el-option label="男" :value="1"></el-option>
          <el-option label="女" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancel">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="员工信息" width="200" align="center">
        <template v-slot="{ row }">
          <div class="user-info">
            <el-image :src="row.avatar ? row.avatar : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/userDefault.png '"></el-image>
            <span>{{ row.name || '--' }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号" align="center">
        <template v-slot="{ row }">
          {{ row.mobile || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="position" label="职位" align="center">
        <template v-slot="{ row }">
          {{ row.position || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="gender" label="性别" align="center">
        <template v-slot="{ row }">
          {{ row.gender == 0 ? '未知' : row.gender == 1 ? '男' : '女' }}
        </template>
      </el-table-column>
      <el-table-column prop="email" label="邮箱" align="center">
        <template v-slot="{ row }">
          {{ row.email || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="department_name" label="部门名称" align="center">
        <template v-slot="{ row }">
          {{ row.department_name || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="激活状态" align="center">
        <template v-slot="{ row }">
          {{ row.status == 1 ? '已激活' : row.status == 2 ? '已禁用' : row.status == 4 ? '未激活' : '成员退出' }}
        </template>
      </el-table-column>
      <el-table-column prop="qr_code" label="企微二维码" align="center">
        <template v-slot="{ row }">
          <el-image v-if="row.qr_code" style="width: 60px; height: 60px" :preview-src-list="[row.qr_code]" :src="row.qr_code"></el-image>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="is_client" label="设置平台客服" align="center">
        <template v-slot="{ row }">
          <el-switch v-model="row.is_client" @change="setIsClient(row)" :active-value="1" :inactive-value="0"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button @click="$router.push({ path: '/user/enterpriseWechat/staffDetail', query: { user_id: row.user_id } })" type="text" size="small">查看客户</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging class="paging" :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '../../../components/paging.vue';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        name: '',
        mobile: '',
        gender: -1,
      },
      list: [],
      total_number: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancel() {
      this.searchForm = {
        page: 1,
        rows: 10,
        name: '',
        mobile: '',
        gender: -1,
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    setIsClient(row) {
      this.$axios.post(this.$api.user.wxwork.setIsClient, {
        id: row.id,
        is_client:row.is_client
      }).then(res=>{
        if(res.code == 0){
          this.getList();
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    getList() {
      this.$axios.post(this.$api.user.wxwork.staffList, this.searchForm).then(res => {
        if (res.code == 0) {
          this.total_number = res.result.total_number;
          this.list = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .user-info {
    display: flex;
    align-items: center;
    .el-image {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
    span {
      flex: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
</style>